import React from "react";
import PropTypes from "prop-types";
import "./BookingSuccess.css";
import { ReactComponent as Done } from "../../assets/images/done.svg";

const BookingSuccess = ({ show }) => {
  return (
    <div className={`BookingSuccess ${show ? "ShowBookingSuccess" : ""}`}>
      <Done className="BookingSuccessIcon" />
      <div className="BookingSuccessMessage">Seat booked successfully.</div>
      <div className="BookingSuccessNumber">0756 862 716</div>
    </div>
  );
};

BookingSuccess.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default BookingSuccess;

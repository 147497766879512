import React from "react";
import "./Toolbar.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "../../firebase";
import DateInput from "../DateInput";
import updateDate from "../../redux/actions/updateDate";
import updateEvents from "../../redux/actions/updateEvents";
import startEventsLoading from "../../redux/actions/startEventsLoading";
import stopEventsLoading from "../../redux/actions/stopEventsLoading";
import { withRouter } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.db = firebase.database();

    this.switchDate = this.switchDate.bind(this);
  }

  componentDidMount() {
    this.initializeDate();
  }

  componentDidUpdate(prevProps) {
    const { date } = this.props;

    if (prevProps.date !== date) {
      this.getEvents();
    }
  }

  initializeDate() {
    const { updateDate, match } = this.props;

    const date = new Date();
    let dateString = "";

    if (match.params.date) {
      dateString = match.params.date;
    } else {
      if (date.getDay() === 0) {
        // today is a sunday
        dateString = `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}`;
      } else {
        // look for soonest sunday
        let currentDay = date.getDate();
        let currentMonth = date.getMonth();
        let currentYear = date.getFullYear();
        let currentDate = new Date(currentYear, currentMonth, currentDay);
        let numberOfDaysInMonth = new Date(
          currentYear,
          currentMonth + 1,
          0
        ).getDate();

        while (currentDate.getDay() !== 0) {
          // loop till sunday
          if (currentDay <= numberOfDaysInMonth) {
            currentDay += 1;
          } else {
            // new month
            currentDay = 1;
            currentMonth += 1;

            if (currentMonth === 12) {
              // new year
              currentMonth = 0;
              currentYear += 1;
            }
          }

          currentDate = new Date(currentYear, currentMonth, currentDay);
        }

        dateString = `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}`;
      }
    }

    updateDate(dateString);
  }

  switchDate(e, { currentYear, currentMonth, currentDay }) {
    const { updateDate, history } = this.props;

    /**
     * month from calendar is zero-based
     * we increment it by one to form a correct date string
     */
    const dateString = `${currentYear}-${currentMonth + 1}-${currentDay}`;

    updateDate(dateString);

    history.push(`/date/${dateString}`);
  }

  getEvents() {
    const { date, startEventsLoading, stopEventsLoading, updateEvents } =
      this.props;

    startEventsLoading();

    this.db.ref(`/tevents/${date}`).on("value", (snapshot) => {
      stopEventsLoading();

      if (snapshot.val()) {
        const events = Object.keys(snapshot.val()).map((id) => ({
          id,
          ...snapshot.val()[id],
        }));

        updateEvents(events);
      } else {
        updateEvents([]);
      }
    });
  }

  render() {
    const {
      date,
      match: {
        params: { eventId },
      },
      history,
    } = this.props;

    return (
      <div className="Toolbar">
        {eventId ? (
          <div
            className="ToolbarBackWrapper"
            onClick={() => {
              history.goBack();
            }}
          >
            <BackIcon />
            <div>
              {eventId === "96dfa6fa-4fa4-4e2b-9a10-247856b7c553"
                ? "second service"
                : "first service"}
            </div>
          </div>
        ) : (
          <DateInput currentValue={date} updateValue={this.switchDate} />
        )}
      </div>
    );
  }
}

Toolbar.propTypes = {
  date: PropTypes.string.isRequired,
  updateDate: PropTypes.func.isRequired,
  eventsLoading: PropTypes.bool.isRequired,
  startEventsLoading: PropTypes.func.isRequired,
  stopEventsLoading: PropTypes.func.isRequired,
  updateEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  date: state.date,
  eventsLoading: state.eventsLoading,
});

const mapDispatchToProps = {
  updateEvents,
  updateDate,
  startEventsLoading,
  stopEventsLoading,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Toolbar)
);

import React from "react";
import "./Event.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Event = ({ event, date }) => (
  <Link
    to={`/date/${date}/events/${event.id}`}
    className="Event"
    style={{
      height: `${
        ((new Date(
          `${`${date.split("-")[0]}-${
            date.split("-")[1] < 10
              ? `0${date.split("-")[1]}`
              : date.split("-")[1]
          }-${
            date.split("-")[2] < 10
              ? `0${date.split("-")[2]}`
              : date.split("-")[2]
          }`}T${event.end}`
        ).getTime() -
          new Date(
            `${`${date.split("-")[0]}-${
              date.split("-")[1] < 10
                ? `0${date.split("-")[1]}`
                : date.split("-")[1]
            }-${
              date.split("-")[2] < 10
                ? `0${date.split("-")[2]}`
                : date.split("-")[2]
            }`}T${event.start}`
          ).getTime()) /
          (60 * 60 * 1000)) *
          3 -
        0.3
      }rem`,
      top: `${event.start.split(":")[1] === "00" ? 0.2 : 1.7}rem`,
    }}
  >
    <div>{event.name}</div>
    <div>
      {`
        ${event.start.replace(/^0/, "").split(":")[0]}:${
        event.start.replace(/^0/, "").split(":")[1]
      }
        - ${event.end.replace(/^0/, "").split(":")[0]}:${
        event.end.replace(/^0/, "").split(":")[1]
      }
        `}
    </div>
  </Link>
);

Event.propTypes = {
  event: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
};

export default Event;

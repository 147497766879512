import React from "react";
import "./Dashboard.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "../../firebase";
import Toolbar from "../Toolbar";
import Spinner from "../Spinner";
import BookingDialog from "../BookingDialog";
import Timeline from "../Timeline";
import Layout from "../Layout";
import { Switch, Route } from "react-router-dom";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.db = firebase.database();
  }

  render() {
    const { date, showBookingDialog, events, eventsLoading } = this.props;

    return (
      <section className="View">
        <Toolbar />
        <BookingDialog show={showBookingDialog} />
        <div className="DashboardWrapper">
          {eventsLoading ? (
            <Spinner />
          ) : (
            <Switch>
              <Route
                path={`/date/${date}/events/:eventId`}
                component={Layout}
              />
              <Route path="/">
                <Timeline events={events} date={date} />
              </Route>
            </Switch>
          )}
        </div>
      </section>
    );
  }
}

Dashboard.propTypes = {
  events: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  eventsLoading: PropTypes.bool.isRequired,
  showBookingDialog: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  events: state.events,
  date: state.date,
  eventsLoading: state.eventsLoading,
  showBookingDialog: state.showBookingDialog,
});

export default connect(mapStateToProps)(Dashboard);

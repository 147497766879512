import React, { useState } from "react";
import "./Profile.css";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import { connect } from "react-redux";
import revokeAccess from "../../redux/actions/revokeAccess";
import TextInput from "../TextInput";
import TelInput from "../TelInput";
import PrimaryButton from "../PrimaryButton";
import { useHistory } from "react-router-dom";

const Profile = ({ user, revokeAccess }) => {
  const { data } = user;
  const history = useHistory();

  const [info, setInfo] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const [isValid, setIsValid] = useState({
    isValidName: null,
    isValidPhone: null,
    isValidAddress: null,
  });

  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        revokeAccess();
        history.push("/");
      });
  };

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
  };

  const handleBlur = (event) => {
    /**
     * validate input on blur
     */
    const { name } = event.target;

    switch (name) {
      case "name":
        validateName();
        break;

      case "phone":
        validatePhone();
        break;

      case "address":
        validateAddress();
        break;

      default:
    }
  };

  const validateName = () => {
    const isValidName = /^[a-zA-Z ]{2,30}$/.test(name.trim());

    setIsValid({
      ...isValid,
      isValidName,
    });
  };

  const validatePhone = () => {
    const isValidPhone = /^(0|\+256)7[0-9]{8}$/.test(phone.replace(/\s/g, ""));

    setIsValid({
      ...isValid,
      isValidPhone,
    });
  };

  const validateAddress = () => {
    const isValidAddress = !!address.trim();

    setIsValid({
      ...isValid,
      isValidAddress,
    });
  };

  const updateDetails = () => {
    // run effect
    setIsLoading(true);
  };

  const { name, phone, address } = info;
  const { isValidName, isValidPhone, isValidAddress } = isValid;

  return (
    <section className="View Profile">
      <div className="ProfileHeader">Your profile</div>
      <div className="ProfileMain">
        <TextInput
          valid={isValidName}
          label="full name"
          name="name"
          value={name}
          placeholder="first last"
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <TelInput
          valid={isValidPhone}
          label="phone number"
          name="phone"
          value={phone}
          placeholder="0700123456"
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <TextInput
          valid={isValidAddress}
          label="physical address"
          name="address"
          value={address}
          placeholder="village, town"
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <PrimaryButton
          label="save"
          handleClick={updateDetails}
          isLoading={isLoading}
        />
      </div>
      <div className="ProfileFooter">
        <div className="Logout" onClick={logOut}>
          Log Out
        </div>
      </div>
    </section>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  revokeAccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  revokeAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

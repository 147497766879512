import React from "react";
import "./BookingDialog.css";
import PrimaryButton from "../PrimaryButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import hideBookingDialog from "../../redux/actions/hideBookingDialog";
import TextInput from "../TextInput";
import BookingSuccess from "../BookingSuccess";
import BookingFailure from "../BookingFailure";
import firebase from "../../firebase";
import { withRouter } from "react-router-dom";

class BookingDialog extends React.Component {
  constructor(props) {
    super(props);

    const { id, name, phone, address } = props.user.data;

    this.state = {
      id,
      phone,
      name: name || "",
      address: address || "",
      registered: name && address,
      isValidName: name ? true : null,
      isValidAddress: address ? true : null,
      isLoading: false,
      showBookingSuccess: false,
      showBookingFailure: {
        show: false,
        reason: "",
      },
    };

    this.db = firebase.database();

    this.book = this.book.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.removeBookingDialog = this.removeBookingDialog.bind(this);
  }

  updateUser({ id, name, address }) {
    this.db.ref(`/users/${id}`).update({
      name,
      address,
    });
  }

  addContact({ phone, name, address }) {
    this.db.ref(`/contacts`).push().set({ phone, name, address });
  }

  updateContacts({ phone, name, address }) {
    this.db
      .ref(`/contacts`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          let phoneExists = false;

          Object.values(snapshot.val()).map(
            (contact) => contact.phone === phone && (phoneExists = true)
          );

          if (!phoneExists) {
            this.addContact({ phone, name, address });
          }
        } else {
          // no contacts - add first contact
          this.addContact({ phone, name, address });
        }
      });
  }

  addBooking() {
    const {
      date,
      seat,
      match: {
        params: { eventId },
      },
    } = this.props;

    const { id, name, phone, address } = this.state;

    this.db
      .ref(`/tevents/${date}/${eventId}/bookings`)
      .push()
      .set({
        seat,
        status: "confirmed",
        temperature: "-",
        user: {
          phone,
          name,
          address,
        },
        dateConfirmed: {
          ".sv": "timestamp",
        },
        bookedBy: "self",
      })
      .then((res) => {
        // booked successfully
        this.setState(
          {
            isLoading: false,
            showBookingSuccess: true,
          },
          () => {
            this.updateUser({ id, name, address });
            this.updateContacts({ phone, name, address });
          }
        );
      })
      .catch((e) => {
        // something failed at our end
        this.setState({
          isLoading: false,
          showBookingFailure: {
            show: true,
            reason: "Sorry, something went wrong.",
          },
        });
      });
  }

  book() {
    const {
      date,
      seat,
      match: {
        params: { eventId },
      },
    } = this.props;

    const { phone, isValidName, isValidAddress } = this.state;

    this.setState({
      isLoading: true,
    });

    if (isValidName && isValidAddress) {
      this.setState(
        {
          showBookingSuccess: false,
          showBookingFailure: {
            show: false,
            reason: "",
          },
        },
        () => {
          this.db
            .ref(`/tevents/${date}/${eventId}/bookings`)
            .once("value")
            .then((snapshot) => {
              let userBooked = false;
              let seatBooked = false;

              if (snapshot.val()) {
                Object.values(snapshot.val()).map(
                  (booking) =>
                    booking.user.phone === phone && (userBooked = true)
                );

                if (userBooked) {
                  this.setState({
                    isLoading: false,
                    showBookingFailure: {
                      show: true,
                      reason: "You booked a service already.",
                    },
                  });
                } else {
                  // check if seat is booked
                  Object.values(snapshot.val())
                    .filter((booking) => booking.status !== "canceled")
                    .map(
                      (booking) => booking.seat === seat && (seatBooked = true)
                    );

                  if (seatBooked) {
                    this.setState({
                      showBookingFailure: {
                        show: true,
                        reason: "Someone booked before you completed.",
                      },
                    });
                  } else {
                    this.addBooking();
                  }
                }
              } else {
                // add first booking for event
                this.addBooking();
              }
            });
        }
      );
    } else {
      this.validateName();
      this.validateAddress();
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleBlur(event) {
    /**
     * validate input on blur
     */
    const { name } = event.target;

    switch (name) {
      case "name":
        this.validateName();
        break;

      case "address":
        this.validateAddress();
        break;

      default:
    }
  }

  validateName() {
    const { name } = this.state;

    const nameRegExp = /^[a-zA-Z ]{2,30}$/;

    const isValidName = nameRegExp.test(name.trim());

    this.setState({
      isValidName,
    });
  }

  validateAddress() {
    const { address } = this.state;

    const isValidAddress = !!address.trim();

    this.setState({
      isValidAddress,
    });
  }

  removeBookingDialog() {
    const { hideBookingDialog } = this.props;

    this.setState({
      showBookingSuccess: false,
      showBookingFailure: {
        show: false,
        reason: "",
      },
    });

    hideBookingDialog();
  }

  render() {
    const { show, seat } = this.props;

    const {
      name,
      address,
      registered,
      isValidName,
      isValidAddress,
      isLoading,
      showBookingSuccess,
      showBookingFailure,
    } = this.state;

    return (
      <div
        className={`BookingDialog ${show ? "ShowBookingDialog" : ""}`}
        onClick={this.removeBookingDialog}
      >
        <div
          className="BookingDialogWrapper"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="BookingDialogHeader">Book your Seat</div>
          <div className="BookingDialogDetails">
            <BookingSuccess show={showBookingSuccess} />
            <BookingFailure show={showBookingFailure} />
            {seat}
          </div>
          <div className="BookingDialogMain">
            {registered ? (
              <div className="BookingDialogMainName">{name}</div>
            ) : (
              <>
                <TextInput
                  valid={isValidName}
                  label="full name"
                  name="name"
                  value={name}
                  placeholder="first last"
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                />
                <TextInput
                  valid={isValidAddress}
                  label="physical address"
                  name="address"
                  value={address}
                  placeholder="village, town"
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                />
              </>
            )}
          </div>
          <div className="BookingDialogFooter">
            <PrimaryButton
              label="book"
              handleClick={this.book}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

BookingDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  hideBookingDialog: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  seat: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  date: state.date,
  seat: state.seat,
  user: state.user,
});

const mapDispatchToProps = {
  hideBookingDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookingDialog)
);

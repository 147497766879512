export const SHOW_BOOKING_DIALOG = "SHOW_BOOKING_DIALOG";
export const HIDE_BOOKING_DIALOG = "HIDE_BOOKING_DIALOG";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const UPDATE_SEAT = "UPDATE_SEAT";
export const UPDATE_DATE = "UPDATE_DATE";
export const SHOW_BOOKING_FAILURE = "SHOW_BOOKING_FAILURE";
export const HIDE_BOOKING_FAILURE = "HIDE_BOOKING_FAILURE";
export const START_EVENTS_LOADING = "START_EVENTS_LOADING";
export const STOP_EVENTS_LOADING = "STOP_EVENTS_LOADING";
export const GRANT_ACCESS = "GRANT_ACCESS";
export const REVOKE_ACCESS = "REVOKE_ACCESS";

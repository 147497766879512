import React from "react";
import "./ReleaseType.css";
import PropTypes from "prop-types";

const ReleaseType = ({ type, name }) => (
  <div
    className={`ReleaseType ${
      type === "0"
        ? "ReleaseTypeFeature"
        : type === "1"
        ? "ReleaseTypeTweak"
        : "ReleaseTypeFix"
    }`}
  >
    {name}
  </div>
);

ReleaseType.propType = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReleaseType;

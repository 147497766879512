import { UPDATE_EVENTS } from "../actions/types";

const events = (events = [], action) => {
  switch (action.type) {
    case UPDATE_EVENTS:
      return action.events;

    default:
      return events;
  }
};

export default events;

import React from "react";
import PropTypes from "prop-types";
import "./BookingFailure.css";
import { ReactComponent as Error } from "../../assets/images/error.svg";

const BookingFailure = ({ show }) => {
  return (
    <div className={`BookingFailure ${show.show ? "ShowBookingFailure" : ""}`}>
      <Error className="BookingFailureIcon" />
      <div className="BookingFailureReason">{show.reason}</div>
      <div className="BookingFailureNumber">0756 862 716</div>
    </div>
  );
};

BookingFailure.propTypes = {
  show: PropTypes.object.isRequired,
};

export default BookingFailure;

import { START_EVENTS_LOADING, STOP_EVENTS_LOADING } from "../actions/types";

const bookingsLoading = (isLoading = false, action) => {
  switch (action.type) {
    case START_EVENTS_LOADING:
      return true;

    case STOP_EVENTS_LOADING:
      return false;

    default:
      return isLoading;
  }
};

export default bookingsLoading;

import React, { useRef, useEffect } from "react";
import "./Timeline.css";
import PropTypes from "prop-types";
import shortid from "shortid";
import Event from "../Event";

const Timeline = ({ date, events }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.scrollTop = 300;
  });

  return (
    <>
      <div className="TimelineYAxis" />
      <div className="Timeline" ref={ref}>
        {[...Array(24)].map((t, i) => (
          <div className="TimelineWrapper" key={shortid.generate()}>
            {events?.map(
              (e) =>
                Number(e.start.split(":")[0]) === Number(i) && (
                  <Event key={shortid.generate()} event={e} date={date} />
                )
            )}
            <div className="TimelineTime">
              {i < 12 ? `${i ? i : 12} am` : `${i - 12 ? i - 12 : 12} pm`}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

Timeline.propTypes = {
  events: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
};

export default Timeline;

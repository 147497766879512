import React from "react";
import "./Releases.css";
import ReleaseType from "../ReleaseType";
import releases from "../../data/releases.json";

const Releases = () => (
  <section className="View Releases">
    <div className="ReleasesHeader">
      <div className="ReleasesHeaderTitle">Release notes</div>
      <div className="ReleasesHeaderSubtitle">Hello, early birds!</div>
    </div>
    <div className="ReleasesMain">
      {Object.keys(releases).map((releaseKey) => (
        <div className="Release" key={releaseKey}>
          <div className="ReleaseHeader">
            <div className="ReleaseVersion">{releases[releaseKey].version}</div>
            <div className="ReleaseDate">{releases[releaseKey].date}</div>
            <div className="ReleaseSummary ReleaseParagraph">
              {releases[releaseKey].summary}
            </div>
          </div>
          <div className="ReleaseMain">
            {Object.keys(releases[releaseKey].notes).map((releaseNoteKey) => (
              <div className="ReleaseNote" key={releaseNoteKey}>
                <ReleaseType
                  type={releases[releaseKey].notes[releaseNoteKey].type}
                  name={releases[releaseKey].notes[releaseNoteKey].name}
                />
                <div className="ReleaseNoteText ReleaseParagraph">
                  {releases[releaseKey].notes[releaseNoteKey].text}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Releases;

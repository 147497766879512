import { combineReducers } from "redux";
import showBookingDialog from "./bookingDialog";
import events from "./events";
import seat from "./seat";
import date from "./date";
import user from "./user";
import eventsLoading from "./eventsLoading";

export default combineReducers({
  showBookingDialog,
  date,
  events,
  seat,
  eventsLoading,
  user,
});

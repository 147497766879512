import React from "react";
import "./Terms.css";
import terms from "../../data/terms.json";

const Terms = () => (
  <section className="View Terms">
    <div className="TermsHeader">
      <div className="TermsTitle">Terms of service</div>
      <div className="TermsSubtitle">Updated: 25th January 2021</div>
    </div>
    <div className="TermsMain">
      {terms.map((term) => (
        <div className="Term" key={term.title}>
          <div className="TermTitle">{term.title}</div>
          <div className="TermBody">{term.body}</div>
        </div>
      ))}
    </div>
  </section>
);

export default Terms;

import React from "react";
import "./Layout.css";
import shortid from "shortid";
import Seat from "../Seat";
import PropTypes from "prop-types";
import updateSeat from "../../redux/actions/updateSeat";
import { connect } from "react-redux";
import showBookingDialog from "../../redux/actions/showBookingDialog";
import firebase from "../../firebase";

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: {
        reserved: [],
      },
    };

    this.db = firebase.database();
  }

  updateSeat({ number, available, reserved }) {
    if (available && !reserved) {
      const { showBookingDialog, updateSeat } = this.props;

      showBookingDialog();
      updateSeat(number);
    }
  }

  componentDidMount() {
    const { date, match } = this.props;

    this.db
      .ref(`/tevents/${date}/${match.params.eventId}`)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          this.setState({
            event: snapshot.val(),
          });
        }
      });
  }

  render() {
    const { event } = this.state;
    // initially event.bookings (from the db) is undefined since no one has booked yet

    return (
      <div className="Layout">
        {[...Array(event.venue?.capacity)].map((s, index) => {
          // get list of booked seats
          const bookedSeats = Object.values(event.bookings || {})
            .filter((booking) => booking.status !== "canceled")
            .map((booking) => booking.seat.toString());

          const seatNumber = (index += 1).toString();
          const seatAvailable = !bookedSeats?.includes(seatNumber);
          const reserved = event.reserved.includes(seatNumber);

          return (
            <Seat
              key={shortid.generate()}
              number={seatNumber}
              handleClick={() => {
                this.updateSeat({
                  number: seatNumber,
                  available: seatAvailable,
                  reserved,
                });
              }}
              available={seatAvailable}
              reserved={reserved}
            />
          );
        })}
      </div>
    );
  }
}

Layout.propTypes = {
  date: PropTypes.string.isRequired,
  updateSeat: PropTypes.func.isRequired,
  showBookingDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  date: state.date,
});

const mapDispatchToProps = {
  updateSeat,
  showBookingDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

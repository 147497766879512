import React from "react";
import "./App.css";
import { Switch, Route, Link } from "react-router-dom";
import Dashboard from "../Dashboard";
import Login from "../Login";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import revokeAccess from "../../redux/actions/revokeAccess";
import Releases from "../Releases";
import Terms from "../Terms";
import { ReactComponent as ProfileIcon } from "../../assets/images/profile.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import Profile from "../Profile";

const App = ({ user }) => {
  const { loggedIn } = user;

  return (
    <div className="App">
      <header>
        <Link to="/" className="Logo">
          <Logo />
          Early Bird
        </Link>
        <nav>
          {loggedIn ? (
            <>
              <Link className="ProfileIcon" to="/profile">
                <ProfileIcon />
              </Link>
            </>
          ) : (
            <Link className="Link" to="/terms">
              terms
            </Link>
          )}
        </nav>
      </header>
      <main>
        <Switch>
          <Route
            exact
            path={["/", "/date/:date", "/date/:date/events/:eventId"]}
            component={loggedIn ? Dashboard : Login}
          />
          <Route path="/profile" component={loggedIn ? Profile : Login} />
          <Route path="/releases" component={Releases} />
          <Route path="/terms" component={Terms} />
        </Switch>
      </main>
      <footer>
        <Link to="/releases">Version 1.0.0 Beta</Link> Copyright{" "}
        {new Date().getFullYear()}{" "}
        <a href="http://kawerewagaba.com" target="_NEW">
          Kawere Wagaba
        </a>
      </footer>
    </div>
  );
};

App.propTypes = {
  user: PropTypes.object.isRequired,
  revokeAccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  revokeAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
